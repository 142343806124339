<template>
  <div class="m-4 flex flex-col route-controller-height route-controller-width shadow-lg rounded-md absolute right-0 top-0 z-10">
    <div class=" bg-white px-4 py-5 sm:px-6 rounded-t-md">
      <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap border-b border-gray-200 pb-2 rounded-md">
        <div class="ml-4 mt-4">
          <h3 class="text-base font-semibold leading-6 text-gray-900">Rute ({{ stops.length }})</h3>
          <p class="mt-1 text-sm text-gray-500">Tilpas ruten ved at ændre rækkefølgen på opgaverne.</p>
        </div>
      </div>
    </div>
    <div class="bg-white flex-grow overflow-auto px-4 space-y-1 ">
      <dragn-drop-route-items
        @zoomToPoint="onZoomToPoint"
        @updateSelectedStops="updateSelectedStops"
      ></dragn-drop-route-items>
    </div>
    <div class="border-t px-4 border-gray-200 bg-white py-5 sm:px-6 rounded-b-md">
      <div class="flex flex-shrink-0 justify-between">
        <move-selected-stops value="" config=""></move-selected-stops>
        <base-button type="button" color="indigo" @click="updateSingleDurationsAndDistance(drivingListId, {start: startLocation, ordered_stops: sortedStops, disposal_point: disposalPoint})">Beregn</base-button>
      </div>
    </div>
  </div>
</template>

<script>

import { UPDATE_DURATIONS_AND_DISTANCES } from '@/store/actions.type';
import DragnDropRouteItems from '@/components/route-viewer/route-controller/DragnDropRouteItems.vue';
import MoveSelectedStops from '@/components/route-viewer/route-controller/MoveSelectedStops.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TheRouteController',
  components: {
    DragnDropRouteItems,
    MoveSelectedStops,
  },
  computed: {
    ...mapState('route', ['stops', 'sortedStops', 'startLocation', 'disposalPoint', 'drivingListId']),
  },
  methods: {
  ...mapActions('drivinglists', {
      updateDurationsAndDistances: UPDATE_DURATIONS_AND_DISTANCES,
    }),
  onZoomToPoint(payload) {
    this.$emit('zoomToPoint', payload);
  },
  updateSelectedStops(payload) {
    this.$emit('updateSelectedStops', payload);
  },
  async updateSingleDurationsAndDistance(id, input) {
    const payload = {
      start: {
        id: input.start.id,
        coordinate: {
          x: parseFloat(input.start.x),
          y: parseFloat(input.start.y),
        },
      },
      ordered_stops: input.ordered_stops.map((stop) => ({
        id: stop.id,
        coordinate: {
          x: parseFloat(stop.x),
          y: parseFloat(stop.y),
        },
      })),
      disposal_point: input.disposal_point ? {
        id: input.disposal_point.id,
        coordinate: {
          x: parseFloat(input.disposal_point.x),
          y: parseFloat(input.disposal_point.y),
        },
      } : null,
    };

    this.isDoneGenerating = false;
    try {
      this.$ntf.info('Starter opdatering af tider og afstande af rute..');
      await this.updateDurationsAndDistances({
        id,
        payload,
      });
    } catch (error) {
      this.$unhandledError(error);
    }
  },
},
};
</script>

<style>
.route-controller-height {
  height: calc(100% - 32px);
  max-height: calc(100% - 32px);
}
.route-controller-width {
  width: 456px;
  max-width: 456px;
}
</style>
